import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ApplicationhttpService } from 'app/applicationhttp.service';
import { data } from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'ms-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent implements OnInit {
  profile_photo:File =undefined;
  profilePic_name: string = 'Browse Photo';
  _validFileExtensions = [".jpg", ".jpeg", ".png"];
  imgURL :string;
  userId:any;
  constructor(
    private ser: ApplicationhttpService,
    public dialogRef: MatDialogRef<ProfilePicComponent>,
    @Inject(MAT_DIALOG_DATA)
    any) { }
  ngOnInit() {
    this.ser.currentMessage.subscribe(message=> this.imgURL = message)
  }

 
 
  case_number = new FormControl();

  onNoClick(): void {
    this.dialogRef.close();
  }


  save_profile_photo(event: any) {
    if (this.ValidateFileSize(event.target.files[0].size / 1024 / 1024)) {
      if (this.ValidateSingleInput(event.target.files[0].name)) {
        this.profile_photo = event.target.files[0];
        this.profilePic_name = event.target.files[0].name;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => { 
          this.imgURL = reader.result as string; 
        }
      } else {
        this.imgURL =undefined;
        this.profile_photo = undefined;
        this.profilePic_name = 'Browse Photo';
      };
    } else {
      this.imgURL =undefined;
      this.profile_photo = undefined;
      this.profilePic_name = 'Browse Photo';
    };
  }

  ValidateSingleInput(oInput): boolean {
    var sFileName = oInput;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < this._validFileExtensions.length; j++) {
        var sCurExtension = this._validFileExtensions[j];
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        Swal.fire('', "Sorry, " + sFileName + " is invalid, allowed extensions are: " + this._validFileExtensions.join(", "), 'warning');

        return false;
      }
    }
    return true;
  }

  ValidateFileSize(size): boolean {
    if (size > 20) {
      Swal.fire('', 'File size should be less than 5MB This file size is: ' + size.toFixed(2) + "MB", 'warning');
      return false;
    } else {
      return true;
    }
  }


  SaveFile() {
    if ( this.profile_photo != undefined) {
      Swal.fire({
        title: "Please wait we are updating your profile...",
        imageUrl: "/assets/img/loading.gif",
        imageHeight: 100,
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let formData: FormData = new FormData();
      formData.append('user_id', localStorage.getItem('LoggedUserID'));
      formData.append('profile_photo', this.profile_photo, this.profile_photo.name);
      console.log(formData);
      this.ser.Postmethod(`api/Employee2/upload_profilepic`, formData).subscribe(data => {
        let personnelinfo :any =data;
        this.ser.changeMessage(personnelinfo.imageUrl);
        this.profile_photo = undefined;
        this.profilePic_name = 'Browse Photo';
        Swal.fire('', 'Profile photo uploaded successfully', 'success');
        this.onNoClick();
      }, error => {
        Swal.fire('', 'Something went wrong', 'error');
      });
    } else {
      if (this.profile_photo != undefined) {
        Swal.fire('', 'Please Browse  Photo', 'warning');
      }
    }
  }

}
