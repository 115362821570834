import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {TokenService} from './token.service';
import { ApplicationhttpService } from 'app/applicationhttp.service';
import {catchError, map} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private ser: ApplicationhttpService
    ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const token = this.tokenService.getToken();
   
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }


    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.tokenService.userLoggedIn.next(true);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.Message === 'Authorization has been denied for this request.') {
           
          } else {
            if (error.status == 401) {
              Swal.fire({
                title:'',
                text:'Session Expired. Authorization has been denied for this request, Please re-login to renew your session',
                type:'warning',
                confirmButtonColor:'#3085d6',
                cancelButtonColor:'#d33',
                allowOutsideClick: false,
                confirmButtonText:'Login'
                }).then((result) => {
                if(result.value){
                window.location.href='https://www.irtsaas.com/authentication/login';
                }
                });
            }
            return throwError(error);
          }
        }
        return throwError(error);
      }));
  }
}
