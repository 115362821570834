import { Injectable } from '@angular/core';
import { ApplicationhttpService} from '../../../applicationhttp.service';
import { state } from '@angular/animations';


export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS: Menu[] = [

  {
    state: 'selfservice',
    name: 'Self Service',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'personaldetails', name: 'My Profile' },
      { state: 'leavelist', name: 'My Leaves' },
      { state: 'timesheet', name: 'My Timesheet' },
      { state: 'myapprisal', name: 'My Appraisal' },
    ]
  },
  {
    state: 'hr',
    name: 'Human Resource',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'employees', name: 'Employees'},
      { state: 'rolesandprivileges', name: 'Roles & Privileges' },
     // { state: 'manageholidaygroups', name: 'Manage Holiday Group' },
     // { state: 'manageholidays', name: 'Manage Holidays' },
     // { state: 'leavemanagementoptions', name: 'Leave Management Options' },
      { state: 'employeeleavesummary', name: 'Employee Leave Summary' },
      { state: 'employeeLeavesList', name: 'Alloted Leaves' },
      { state: 'pastweeklyreports', name: 'Past Weekly Reports' }
    ]
  },
  {
    state: 'employeeconfig',
    name: 'Employee Configuration',
    type: 'sub',
    icon: 'security',
    children: [
    //  { state: 'employeetabs', name: 'Employee Tabs' },
      { state: 'empstatuslist', name: 'Employment Status' },
      { state: 'payfrequencylist', name: 'Pay Frequency' },
      { state: 'remunbasic', name: 'Remuneration Basis' },
      { state: 'jobtitle', name: 'Job Title' },
      { state: 'positions', name: 'Position' },
      { state: 'competencylevels', name: 'Competency Levels' },
      { state: 'educationlevels', name: 'Education Levels' },
      { state: 'languages', name: 'Languages' },
      { state: 'leavetypelist', name: 'Leave Types' },
      { state: 'attendancestatus', name: 'Attendance Status' },
      { state: 'bankaccounttypes', name: 'Bank Account Types' },
      { state: 'identitydocuments', name: 'Identity Documents' },
      // { state: 'eeoccategories', name: 'EEOC Categories' },
      // { state: 'workeligibilitydocumenttypes', name: 'Work Eligibility Document Types' },
      // { state: 'veteranstatus', name: 'Veteran Status' },
      // { state: 'militaryservicetypes', name: 'Military Service Types' }

    ]
  }
  ,
  {
    state: 'appraisals',
    name: 'Appraisals',
    type: 'sub',
    icon: 'security',
    children: [
      // { state: 'configurationparameters', name: 'Configuration Parameters' },
      // { state: 'configurationquestions', name: 'Configuration Questions' },
      // { state: 'configurationskills', name: 'Configuration Skills' },
      // { state: 'configurationratings', name: 'Configuration Ratings' },
      { state: 'appraisalsList', name: 'Appraisals List' },
    //  { state: 'myapprisal', name: 'My Appraisal' },
      // { state: 'initializeappraisal', name: 'Initialize Appraisal' },
      // { state: 'managerappraisal', name: 'Manager Appraisal' },
      // { state: 'managerstatus', name: 'Manager Status' },
      // { state: 'empstatus', name: 'Employee Status' },
      // { state: 'selfappraisalmy', name: 'Self AppraisalMy' },
      // { state: 'teamappraisal', name: 'Team Appraisal' },
      // { state: 'appraisalhistory', name: 'Appraisal History' },
      // { state: 'myappraisalhistory', name: 'My Appraisal History' },
      // { state: 'teamappraisalhistory', name: 'Team Appraisal History' },
      { state: 'questions', name: 'Questions' },
      // { state: 'initializefeedforward', name: 'Initialize Feedforward' },
      // { state: 'appraiseyour manager', name: 'Appraise Your Manager' },
      // { state: 'managerfeedforward', name: 'Manager Feedforward' },
      // { state: 'employeestatus', name: 'Employee Status' },

    ]
  }
  ,
  {
    state: 'organization',
    name: 'Organization',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'organizationinfo', name: 'Organization Configuration' },
      { state: 'emailconfigurationinfo', name: 'Email Configuration' },
      // { state: 'businessunits', name: 'Business Units' },
      // { state: 'departments', name: 'Departments' },
      // { state: 'organizationstructure', name: 'Organization Structure' },
      // { state: 'organizationhierarchy', name: 'Organization Hierarchy' },
      // { state: 'announcements', name: 'Announcements' },
      // { state: 'policydocuments', name: 'Policy Documents' },
      // { state: 'managecategories', name: 'Manage Categories' },

    ]
  }
  // ,
  // {
  //   state: 'recruitments',
  //   name: 'Recruitments',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     { state: 'openingorpositions', name: 'Opening or Positions' },
  //     { state: 'candidates', name: 'Candidates' },
  //     { state: 'interviews', name: 'Interviews' }
  //   ]
  // }
  ,

  {
    state: 'time',
    name: 'Time',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'timesheetmanage', name: 'Timesheet Management' },
     // { state: 'timesheet', name: 'Timesheet' },
      //{ state: 'employeetimesheets', name: 'Employee Timesheets' },
      { state: 'clientslist', name: 'Clients' },
      //{ state: 'defaulttasks', name: 'Default Tasks' },
      { state: 'projectslist', name: 'Projects' },
      //{ state: 'configuration', name: 'Configuration' }

    ]
  },
  {
    state: 'preemployment',
    name: 'Pre Employment',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'backgroundcheckauthorizationform', name: 'Background Check Authorization'},
      { state: 'educationemploymenthistoryquestionnaire', name: 'Education Employment History' },
    ]
  }
  // {
  //   state: 'employee',
  //       name: 'Employee',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     { state: 'leavelist' , name: 'Leaves' },
  //     { state: 'timesheets' , name: 'Timesheets' },
  //     {state: 'submitappraisal', name: 'Submit Appraisal' }

  //   ]
  // }

];
const MENUITEMS1: Menu[] = [
  // {
  //   state: 'siteconfig',
  //   name: 'Site Config',
  //   type: 'link',
  //   icon: 'home'
  // }
  // ,
  {
    state: 'organizationconfig',
    name: 'Organization Config',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'emailconfig',
    name: 'Email Config',
    type: 'link',
    icon: 'home'
  }
  // {
  //   state: 'authentication',
  //   name: 'AUTHENTICATION',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'login', name: 'LOGIN'},
  //     {state: 'register', name: 'REGISTER'},
  //     {state: 'forgot-password', name: 'FORGOT'},
  //     {state: 'lockscreen', name: 'LOCKSCREEN'}
  //   ]
  // }

];

@Injectable()

export class MenuItems {

  constructor( private ser: ApplicationhttpService, ) {}
  DynamicMenu :any;
  getAll(): Menu[] {
    return MENUITEMS;
  }
  getAll1(): Menu[] {
    return MENUITEMS1;
  }
  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
  GetDynamicMenu(){
  }
}
