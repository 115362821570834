import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const REFRESH_TOKEN_EXPIRY ="refresh_token_expiry";
const GUSTO_ACCESS_TOKEN="gusto_access_token";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
    public userLoggedIn = new Subject<boolean>();
    public GustouserLoggedIn = new Subject<boolean>();
  constructor(  ) {
    this.userLoggedIn.next(false);
    this.GustouserLoggedIn.next(false);
   }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  setGustoUserLoggedIn(GustouserLoggedIn: boolean) {
    this.GustouserLoggedIn.next(GustouserLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  
  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  getRefreshTokenSlidationTime(): string {
    return localStorage.getItem(REFRESH_TOKEN_EXPIRY);
  }

  saveToken(token): void {
    localStorage.setItem(ACCESS_TOKEN, token);
    this.userLoggedIn.next(true);
  }

  saveRefreshToken(refreshToken): void {
    this.userLoggedIn.next(true);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    this.userLoggedIn.next(false);
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    this.userLoggedIn.next(false);
    localStorage.removeItem(REFRESH_TOKEN);
  }

  saveGustoToken(token): void {
    this.GustouserLoggedIn.next(true);
    localStorage.setItem(GUSTO_ACCESS_TOKEN, token);
   
  }
  removeGustoToken(): void {
    this.GustouserLoggedIn.next(false);
    localStorage.removeItem(GUSTO_ACCESS_TOKEN);
  }
  getGustoToken(): string {
    return localStorage.getItem(GUSTO_ACCESS_TOKEN);
  }
  getGustoUserLoggedIn(): Observable<boolean> {
    return this.GustouserLoggedIn.asObservable();
  }
  
}
